import React, {useState} from "react";
import { Box, Text, Button } from "theme-ui";

export default ({ initial }: { initial?: number }) => {
  const [counter, setCounter] = useState(initial || 0);

  return <div>
    <div>counter={counter}</div>
    <button onClick={() => setCounter(c => c + 1)}>increase</button>
    <Box sx={{ py: 2 }}>
      <Text sx={{ mr: 2 }} >Some styled text</Text>
      <Button sx={{ mr: 2 }} variant="primary">Primary Action</Button>
      <Button sx={{ mr: 2 }} variant="secondary">Secondary Action</Button>
    </Box>
  </div>;
}
