import React from "react";
import { Button, useThemeUI } from "theme-ui";

export default () => {
  const { theme: { rawColors }, setColorMode } = useThemeUI()

  console.log("rawColors", rawColors);
  return Object.entries(rawColors?.modes || {}).map(([mode, values]) => (
    <Button
      key={mode}
      sx={{ bg: values.background, color: values.text }}
      onClick={() => setColorMode(mode)}
    >
      {mode}
    </Button>
  ));
}

